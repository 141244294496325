<template>
  <div>
    <v-container fluid class="pa-6">
      <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl" @openSuperlead="openSuperlead" @openSuperleadForm="openSuperleadForm"/>
    </v-container>
  </div>
</template>

<script>
import DatatableV2 from "@/components/DatatableV2";
import GenericDataService from '@/services/GenericDataService';

export default {
  name: "OperationDetailLeadList",
  components: {
    DatatableV2,
  },
  data() {
    return {};
  },
  created() {
    setTimeout(() => {
      if (this.$store.state.base.submenu.length == 0) {
        GenericDataService.getData("/menu/getsubmenu?module=operation").then((response) => {
            let submenu = response.data.data;
            this.$store.dispatch("base/SET_SUB_MENU", submenu);
          }
        );
      }
    }, 200);
  },
  computed: {
    apiUrl() {
      return "/operation/" + this.$route.params.operation_id + "/superleadList";
    },
  },
  methods: {
    openSuperlead(superlead){
      GenericDataService.getData('superlead/get?id='+superlead.id+'&operation_id='+(superlead.operation_id.id ? superlead.operation_id.id : superlead.operation_id)).then((response) => {
        this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
      });
    },
    openSuperleadForm(id, superlead){
        this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", superlead);
    }
  },
};
</script>
