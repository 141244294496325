<template>
    <div>
        <v-container fluid class="pa-6">
            <v-row>
                <v-col cols="12">
                   <DatatableV2 :displayTitle="false" :url="apiUrl"  @openDrawerObject="openDrawerObject" ref="opportunitiesOpeTableList"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"OperationsDetailOpportunity",
    components:{DatatableV2},
    data() {
        return {
            
        }
    },
    watch: {
    todo: {
      deep:true,
      handler(val){
          if(!val.hasOwnProperty('id')){
            setTimeout(() => {
                this.$refs.opportunitiesOpeTableList.readDataFromAPI();
            }, 100);
          }   
      }
           
    },
  },
    computed: {
        apiUrl(){
            return "/operation/"+this.$route.params.operation_id+"/listOpportunities"
        },
        todo(){
            return this.$store.state.auth.todoForDrawer
        },
    },
    created() {
        
    },
    methods: {
        openDrawerObject(object){
        
        // } else {
            GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + object.id).then((response) => {
                this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: !this.$store.state.auth.openTodoActionDrawer, todo: object, excerpt : response.data.data});
            });   
        // }
    }
    },
}
</script>