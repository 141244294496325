<template>
    <div>
        <v-container fluid class="pa-6">
            <v-row>
                <v-col cols="12">
                    <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrl" @addPassage="addPassage" @openSuperlead="openSuperlead"/>
                </v-col>
            </v-row>
            <v-dialog width="500" v-model="openPlaceChoice">
                <v-card>
                    <v-toolbar tile flat dark class="bg-gradient">
                        <v-toolbar-title>{{$t('selectPlaceForPassage')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn outlined depressed small class="mr-2" @click="openPlaceChoice = false">{{$t('cancelLabel')}}</v-btn>
                        <v-btn outlined depressed small @click="confirmPlace">{{$t('confirmLabel')}}</v-btn>
                    </v-toolbar>
                    <v-card-text class="pt-6">
                        <v-select :items="operationsPlaces" :item-value="'id'" return-object :label="$t('choosePlace')" filled dense v-model="placeSelected">
                            <template v-slot:selection="data">
                                {{ data.item.config.name }}
                            </template>
                            <template v-slot:item="data">
                                {{ data.item.config.name }}
                            </template>
                        </v-select>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import DatatableV2 from '@/components/DatatableV2'

export default {
    name:"OperationsDetailPassage",
    components:{DatatableV2},
    data() {
        return {
            operation:{},
            openPlaceChoice:false,
            operationsPlaces:[],
            placeSelected: {}
        }
    },
    computed: {
        apiUrl(){
            return "/operation/"+this.$route.params.operation_id+"/getPassages"
        }
    },
    created() {
        GenericDataService.getData('operation/'+this.$route.params.operation_id+'/get').then((response) =>{
            this.operation = response.data.data
            this.operationsPlaces = response.data.data.places;
            this.placeSelected= response.data.data.places[0]
        })
    },
    methods: {
        openSuperlead(passage) {
            GenericDataService.getData('superlead/getByRdvId?id=' + passage.id + ('&operation_id=' + (passage.operation_id.id ? passage.operation_id.id : passage.operation_id))).then((response)=> {
                this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
            });
        },
        addPassage(){
            if(this.operation.places && this.operation.places.length == 1 ){
                //direct create
                this.recordPassage(this.operation.places[0]);
            } else {
                //choice place
                this.openPlaceChoice = true
            }
        },
        confirmPlace(){
            if(this.placeSelected && this.placeSelected.hasOwnProperty('id')){
                this.recordPassage(this.placeSelected)
            }
        },
        recordPassage(place){
            let passage = {}
            passage.type = "PASSAGE"
            passage.date_start = this.operation.config.date_start
            passage.date_end = this.operation.config.date_end
            passage.operation_id = this.$route.params.operation_id
            if(this.$store.state.auth.currentUser.loggedAs){
                passage.user_id = this.$store.state.auth.currentUser.loggedAs.id
            } else {
                passage.user_id = this.$store.state.auth.currentUser.profile.id
            }
            passage.place = place
            passage.status = 'CONFIRMED'
            passage.links = {}
            passage.links.user = []
            
            let payload = {};
                payload.operation_id = this.$route.params.operation_id;
                payload.entity_id = place.config.entity_id[0];
                payload.rdv = passage

            GenericDataService.postData('/superlead/saveStep1', payload).then((response) => {
                this.openPlaceChoice = false
                this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
            })
        }
    },
}
</script>